import styled from "@emotion/styled"
import React from "react"
import { FaInfoCircle } from "react-icons/fa"
import { theme } from "../../utils/theme"
import { rhythm, underline } from "../../utils/typography"

const Container = styled.p`
  svg {
    vertical-align: text-top;
    margin-right: ${rhythm(1 / 4)};
  }
`

const BlockContainer = styled(Container.withComponent("div"))`
  border: 2px solid ${theme.colors.primary};
  color: #fff;
  background-color: ${theme.colors.primary};
  border-radius: ${rhythm(1 / 8)};
  padding: 1rem;

  a {
    color: #fff;
    background-image: ${underline("#fff")};
    &:hover {
      background-image: none;
    }
  }

  p {
    &:first-of-type {
      display: inline;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    & + p {
      margin-top: 1.45rem;
    }
  }
`

export const Info = ({ topic, children }) => {
  return (
    <Container>
      <FaInfoCircle />
      <strong>{topic}</strong>
      <span> &ndash; </span>
      {children}
    </Container>
  )
}

export const InfoBlock = ({ className, children }) => {
  return (
    <BlockContainer className={className}>
      <FaInfoCircle />
      {children}
    </BlockContainer>
  )
}
