import styled from "@emotion/styled"
import React from "react"

const Container = styled.div`
  padding-bottom: 56.25%; // 9/16 for the 16:9 ratio
  position: relative;
  display: block;
  width: 100%;
`

export const Video = ({ url, title }) => (
  <Container className="video">
    <iframe
      src={url}
      title={title}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width="100%"
      height="100%"
      style={{ position: "absolute", top: 0, left: 0 }}
    />
  </Container>
)
